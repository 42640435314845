.grid {
    width: 100%;
    background-color: #ffffff;
}

.grid-content-container {
    height: 450px;
    overflow: auto;
}

.grid .grid-row {
    display: flex;
    border-bottom: #cccccc 1px solid;
}

.grid .grid-row:hover {
    background: #efefef;
}

.grid .grid-row .grid-header {
    width: 225px;
    font-weight: bold;
    padding: 5px 20px;
    font-size: 14px;
}

.grid .grid-row .grid-header-actions {
    flex: 1;
    font-weight: bold;
    padding: 5px 20px;
    font-size: 14px;
}

.grid .grid-row .grid-content {
    width: 225px;
    padding: 5px 20px;
    font-size: 14px;
}

.grid .grid-row .grid-content-actions {
    flex: 1;
    padding: 5px 20px;
    font-size: 14px;
    text-align: center;
}

.grid .grid-row .grid-content-actions svg {
    cursor: pointer;
    margin-right: 10px;
}

.text-field {
    margin: 10px;
}

.data-grid-container {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    border: #2E1B46 1px solid;
    height: auto;
}

.data-grid {
    border: none !important;
}

.delete-modal-template-name {
    font-weight: bold;
}
.tabs {
    padding-top: 0px;
    background-color: #ffffff;
    border-bottom: 0px !important;
}

.tabs .MuiTab-root {
    margin-top: 5px;
    border: #cccccc 1px solid;
    border-radius: 8px 8px 0 0;
}

.tabs .Mui-selected {
    background: #976CAE;
    color: #ffffff !important;
    border-bottom: #2E1B46 2px solid !important;
    margin-bottom: 0px;
}

.MuiTabs-indicator {
    background-color: #2E1B46 !important;
}

.search-container {
    border: 1px solid #cccccc;
}