.child-container {
  background-color: white !important;
}

.add-record-button-container {
  margin-top: 100px !important;
  /* width: 150px !important; */
  height: 50px !important;
  margin-left: auto !important;
  margin-right: 193px !important;
}
.addrecord-button {
  background-color: #5c3883 !important;
  color: white !important;
  height: 36px;
}

.audit-notifications-table-container {
  border-top: 1px solid #cccccc;
}

.audit-notifications {
  display: flex;
  flex-direction: column;
}

.audit-notification-dialog {
  width: 600px;
  height: 700px;
  overflow: hidden !important;
}

.email-address-container {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px 0;
}

.email-address-field {
  width: 200px !important;
}

.email-address-error {
  margin: 0px 0 15px 0;
  height: 20px;
  color: red;
}

.audit-properties-container {
  height: 415px;
}

.audit-properties {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0 -2px 0 0 !important;
}

.dialog-actions {
  width: 270px;
  display: flex;
  justify-content: space-between;
  margin: 50px 25px 0px 22px;
}

.table-actions {
  width: 250px;
  display: flex;
  justify-content: space-evenly;
}

.table-delete-button {
  border: 1px solid #976cae !important;
  color: #976cae !important;
  margin-left: 30px;
}

.MuiButtonBase-root .MuiButton-root {
  background-color: #5c3883;
}
