.header {
  background-color: #5c3883;
  text-align: right;
  padding: 15px 25px;
  border-bottom: #2e1b46 1px solid;
  color: #ffffff;
  display: flex;
  justify-content: right;
  border-left: #cccccc 1px solid;
}

.header .header-link {
  color: #ffffff;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
}

.header-icon {
  color: #ffffff;
  fill: #ffffff;
}

.header-account {
  padding: 0 15px;
}

.header-name {
  font-size: 130%;
}

.header-login {
  padding-top: 4px;
}

.header-icon {
  margin: 0px 10px 0 10px;
  cursor: pointer;
  font-size: 2rem !important;
  color: #ffffff;
}

.notifications-icon {
  font-size: 24px !important;
  cursor: pointer;
}
