html, body, #root {
    margin: 0px;
    padding: 0px;
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
}

body {
    background-color: #2E1B46;
    overflow: hidden;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999;
    opacity: 1; /* Firefox */
  }

.MuiBox-root {
    padding-top: 30px;
}

.MuiCircularProgress-root {
    margin-left: auto;
    margin-right: auto;
}

.warning {
    color: red;
    background-color: pink;
    padding: 10px;
    font-weight: bold !important;
}

.warning svg {
    color: red;
    vertical-align: top;
    margin: 0 10px 10px 0;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #5C3883 !important;
}