.search {
    background-color: #976CAE;
    text-align: right;
}

.search .input {
    background: #ffffff;
    padding: 10px 0 10px 0;
    width: 100%;
}

.search .input .field {
    height: 30px;
    width: 300px;
    border-radius: 8px;
    border: none;
    background-color: #ffffff;
    padding: 0 10px;
    margin: 0 10px;
}

.search .input .search-icon {
    margin-top: 13px;
    height: 25px;
    width: 25px;
    color: #2E1B46;
}

.search .input .search-clear {
    margin-top: 13px;
    height: 20px;
    width: 20px;
    color: #2E1B46;
    cursor: pointer;
    margin-right: 10px;
}