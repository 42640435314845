.bee-plugin-init {
    text-align: center;
    padding-top: 100px;
    font-size: 16px;
    font-weight: bold;
}

#bee-plugin-container {
    height: 100%;
    background-color: #ffffff;
}

.template-name-container {
    display: flex;
    padding: 10px 10px 0 10px;
    background-color: #ecd4f9;
    padding: 10px;
    border-bottom: #cccccc 1px solid;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.template-name {
    font-weight: bold;
    padding: 0 20px 10px 20px;
    font-size: 22px;
    flex: 1;
}

.template-version {
    padding: 0 20px 10px 20px;
    text-align: right;
    font-size: 18px;
    flex: 1;
}

.temp-name-fld {
    max-width: 300px;
    width: 100%;
    border-radius: 8px;
    border: none;
    background-color: #ffffff;
    padding: 0 10px;
    margin: 0 !important;
}

.settings-icon {
    margin: 14px 10px;
    font-size: 2em !important;
    cursor: pointer;
}

.editor-toolbar-container {
    background-color: #ecd4f9;
}

.settings-header {
    padding-left: 20px !important;
}

.preview-code-container {
    background-color: #ffffff;
    height: 100%;
}

.jsoneditor-react-container {
    height: 250px;
}

.test-email-name-container {
    display: flex;
    padding: 10px 10px 0 10px;
    background-color: #eeeeee;
    padding: 10px;
    border-bottom: #cccccc 1px solid;
}

.code-toolbar {
    text-align: right;
    padding: 7px;
}

.code-toolbar svg {
    cursor: pointer;
}