.base-view {
    display: flex;
    height: 100%;
    padding-top: 0px;
}

.base-view .right {
    flex: 5;
    height: 100%;
    background-color: #ffffff;
    padding-top: 0px;
}

.base-view .logo {
    height: 75px;
}

.base-view .logo-collapsed {
    height: 50px;
}

.base-view .right .content {
    height: auto;
    background-color: #976CAE;
    border-left: #cccccc 1px solid;
}

.base-view .left {
    background: #2E1B46;
    flex: 1;
    height: auto;
    padding-top: 8px;
    text-align: left;
    position: relative;
}

.base-view .left .icon {
    padding-left: 0px;
    padding-bottom: 10px;
}

.base-view .left .logo-text {
    color: #ffffff;
    padding-left: 20px;
    font-size: 1.8rem;
    margin-top: -10px;
}

.base-view .left-collpased {
    background: #2E1B46;
    width: 90px;
    height: 100%;
    padding-top: 25px;
    text-align: left;
    position: relative;
}

.base-view hr {
    margin: 10px;
    border: 1px solid #999;
}

.nav-item {
    color: #fff;
    padding: 8px;
    cursor: pointer;
    display: flex;
}

.nav-item:hover .nav-icon {
    background-color: #976CAE;
}

.nav-item .nav-icon {
  flex: 1;
  border-radius: 8px;
  padding: 5px;
  text-align: center;
}

.nav-txt {
  flex: 4;
    padding-top: 14px;
    text-align: left;
    font-weight: bold;
    padding-left: 20px;
}

.base-view .left-collpased .nav-txt {
    display: none;
}

.base-view .left-collpased .icon img {
    width: 100%;
}

.base-view .left-collpased hr {
    display: none;
}

.close-icon {
    text-align: right;
    margin-right: 10px;
    position: absolute;
    right: -24px;
    border-radius: 15px;
    padding: 2px 3px 0 2px;
    border: 2px solid #2E1B46;
    top: 12px;
    background: rgb(151,108,174);
    background: linear-gradient(0deg, rgba(151,108,174,1) 0%, rgba(255,255,255,1) 100%);
}

.close-icon svg {
    fill: #333333;
    cursor: pointer;
}

.child-container {
    padding-top: 0;
    overflow: auto;
    background-color: #976CAE;
}

